:root {
    --primary-color-dark: #0c214a; 
    --secondary-color-light: #f7efee;
    --primary-color: #d0a8b3;
}

@font-face {
    font-family: CalibreLight; 
    src: url('../calibre-font/CalibreThin.woff')
}

@font-face {
    font-family: CalibreMedium; 
    src: url('../calibre-font/CalibreRegular.woff');
}

@font-face {
    font-family: CalibreBold; 
    src: url('../calibre-font/CalibreMedium.woff');
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: CalibreBold;
}

a {
    text-decoration: none;
}

.main-button {
    text-decoration: none;
    font-family: CalibreBold;
    color: var(--primary-color-dark);
    border: 2px solid var(--primary-color-dark);
    padding: 1rem 1.5rem 0.8rem 1.5rem;
    border-radius: 5px;
    font-size: 1.2rem;
    position: relative;
    z-index: 1;
}

.main-button::before {
    position: absolute;
    top: -15%;
    left: -5%;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    background-color: rgba(208,168,179,0.4);
    z-index: -1;
    content: '';
    transition: 0.3s ease-in-out;
}

.main-button:hover::before{
    top: 0;
    left: 0;
}

.fancy-link {
    position: relative;
    display: inline;
    z-index: 1; 
}
  
.fancy-link::after {
    content: '';
    position: absolute;
    left: -5%;
    bottom: 40%;
    width: 100%;
    height: 100%;
    background-color: rgba(141, 53, 76, 0.1);
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-transition: -webkit-transform 250ms ease-in;
    transition: -webkit-transform 250ms ease-in;
    transition: transform 250ms ease-in;
    transition: transform 250ms ease-in, -webkit-transform 250ms ease-in;
    z-index: -1; 
}
  
.fancy-link:hover::after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
}

.font-weight--bold {
    font-family: 'CalibreBold';
}

.image-fluid {
    width: 100%;
    height: auto;
}

.visible {
    opacity: 1 !important;
    transform: translateY(0) !important;
}

.navbar {
    background-color: var(--secondary-color-light);
}

.navbar .navbar--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 4rem;
}

.navbar .navbar__items {
    display: flex; 
    list-style-type: none;
    font-family: CalibreLight; 
}

.navbar .navbar__items .active {
    position: relative;
    font-family: CalibreLight; 
}

.navbar .navbar__items .active:before {
    content: '';
    width: 100%;
    height: 80%;
    position: absolute;
    top: -30%;
    left: -10%;
    z-index: 1;
    background-color: rgba(208,168,179,0.2);
    border-radius: 1px; 
}

.navbar .navbar__items .navbar__item {
    margin: 0 2rem;
}

.navbar .navbar__item .navbar__link { 
    color: var(--primary-color-dark);
    font-size: 1.3rem;
}

.navbar .navbar__line {
    padding: 1.5rem 1.2rem;
    display: none;
}

.navbar .navbar__mobile {
    display: none;
    padding: 0 1.5rem 2rem;
}

.navbar .navbar__items--mobile {
    list-style-type: none;
}

.navbar .navbar__item--mobile {
    margin-bottom: 0.8rem;
}

.navbar .navbar__link--mobile {
    font-family: CalibreMedium;
    color: var(--primary-color-dark);
    font-size: 1.1rem;
}

.navbar .navbar--line__one, .navbar--line__three {

}

.navbar .navbar--line__two {
    width: 50px; 
    height: 4px;
    background-color: var(--primary-color-dark);
    margin-bottom: 0.4rem;
    position: relative; 
}

.navbar .navbar--line__two:before {
    content: ''; 
    width: 35px; 
    height: 4px;
    background-color: var(--primary-color-dark);
    margin-bottom: 0.4rem;
    position: absolute;
    top: -10px; 
}

.navbar .navbar--line__two:after {
    content: ''; 
    width: 35px; 
    height: 4px;
    background-color: var(--primary-color-dark);
    margin-bottom: 0.4rem;
    position: absolute;
    top: 10px;     
}

p {
    font-family: CalibreLight;
    color: var(--primary-color-dark);
    font-size: 1.4rem;
}

.container {
    display: flex;
    align-items: center;
}

.main-header {
    background-color: var(--secondary-color-light);
}

.main-header .container  {
    padding: 6rem 4rem 12rem 4rem;
    position: relative;
}

.column-ten--lg {
    flex: 0 0 100%;
    max-width: 100%; 
}

.column-eight--lg {
    flex: 0 0 80%;
    max-width: 80%; 
}

.column-seven--lg {
    flex: 0 0 70%;
    max-width: 70%; 
}

.column-six--lg {
    flex: 0 0 60%;
    max-width: 60%;
}

.column-sixhalf--lg {
    flex: 0 0 65%;
    max-width: 65%;
}

.column-five--lg {
    flex: 0 0 50%;
    max-width: 50%;
}

.column-four--lg {
    flex: 0 0 40%;
    max-width: 40%;   
}

.column-three--lg {
    flex: 0 0 30%;
    max-width: 30%;   
}

.column-two--lg {
    flex: 0 0 20%;
    max-width: 20%;   
}

.main-heading {
    font-family: CalibreBold;
    font-size: 5.5rem;
    font-weight: bolder;
    color: var(--primary-color-dark);
    margin: 1rem 0;
    line-height: 0.6;
}

.secondary-heading {
    font-family: CalibreBold;
    color: var(--primary-color-dark);
    font-size: 2.5rem;    
    margin-bottom: 0.5rem;
    position: relative;
    display: inline-block;
}

.secondary-para {
    line-height: 1.5;
    font-size: 1.5rem;
    margin: 0 0 2rem 0; 
}

.main-heading-two {
    color: rgba(208,168,179,0.8);
}

.main-subheading {
    font-family: CalibreLight;
    color: var(--primary-color-dark);
    font-size: 1.8rem;
    margin: 0 0 2.4rem 0;
    padding: 1rem 0;
    width: 70%;
}

.main-header .main--line {
    position: relative;
    left: 25%; 
    width: 20px;
}

.main-header .main--icons {
    display: flex; 
    align-items: center;
    flex-wrap: wrap; 
}

.main-header .main--icon {
    padding-bottom: 2.5rem;
    color: var(--primary-color-dark);
    transition: 0.3s ease-in-out;
}

.main-header .main--icons a {
    opacity: 0;
}

.main-header .main--icon:hover {
    transform: scale(1.12);
}

.main-header .main--verticle-line {
    position: relative;
    z-index: 1;
    height: 120px;
    width: 2px; 
    background-color: var(--primary-color-dark);
    margin: 0 auto;
}

.main-header .main--verticle-line:after {
    content: '';
    background-color: rgba(208,168,179,0.6);
    width: 2px;
    height: 120px;
    position: absolute;
    left: -500%;
    top: 50%;
    z-index: 10;
}

/* about me */
.about-me {
    background-color: white;   
    border-top: 12px solid rgba(208,168,179,1);
    transform: skewY(-2deg);
    position: relative;
    top: -25px;
}

.about-me .secondary-heading {
    margin-bottom: 1rem;
}

.about-me .about-me--box {
    padding: 0 4rem 0 0;
}

.about-me .about-me--para {
    padding-bottom: 1rem;
    line-height: 1.5;
    margin: 0;
}

.about-me .about-me--para-two {
    margin-bottom: 1rem !important;
}

.about-me .about-me--button{
    text-decoration: none;
    font-family: CalibreBold;
    color: var(--primary-color-dark);
    padding: 0.7rem 1rem 0 1rem;
    font-size: 1.4rem;
    border-bottom: 3px solid var(--primary-color);
    background-color: rgba(208,168,179,0.1);
}
.about-me .about-me--button i {
    margin-left: 0.5rem;
    font-size: 1.1rem;
}

.about-me .about-me--image {
    border-radius: 2%;
    filter: grayscale(40%);
    transform: scale(0.90);
}

.about-me .column-three--lg {
    position: relative;
}

.about-me .about-me--border {
    position: absolute;
    width: 90%;
    height: 90%;
    top: -5%;
    right: -5%;
    border: 2px solid var(--primary-color-dark);
}

.about-me .about-me--image-overlay {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(208,168,179,0.1);
    transition: 0.2s ease-in-out;
    border-radius: 0;
}

.about-me .container {
    padding: 8rem 4rem 7rem 4rem;
    transform: skewY(2deg);
}

/* my skills */ 
.my-skills {
    background-color: var(--secondary-color-light);
    transform: skewY(2deg);
}

.my-skills .my-skills--title {
    transform: skewY(-2deg);
    padding: 4rem 4rem 0 4rem;  
}

.my-skills .my-skills--container {
    transform: skewY(-2deg);
    padding: 2rem 4rem 10rem 4rem;  
}

.my-skills h4 {
    color: var(--primary-color-dark);
    font-size: 1.4rem;
}

.my-skills .my-skills--row{
    width: 100%;
    display: flex; 
    justify-content: center;
}

.my-skills .column-two--lg {
    padding: 1rem;
}

.my-skills .about-me--skills {
    font-family: CalibreBold;
    color: var(--primary-color-dark);
    padding: 0.5rem 1.1rem 0.1rem;
    margin-right: 0.6rem;
    margin-bottom: 1rem;
    border: 2px solid var(--primary-color-dark);
    border-radius: 5px;
    display: inline-block; 
}

.my-skills .about-me--skills {
    background-color: transparent;
    transition: .2s ease;
    position: relative;
    z-index: 1;
}

.my-skills .about-me--skills:after {
    content: ''; 
    position: absolute;
    z-index: -1;
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    background-color: rgba(208,168,179,0.3);
    transition: .35s ease-in;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
    opacity: 0;
}

.my-skills .about-me--skills:hover:after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 1;
  }

/* featured work */ 
.featured-work {
    border-top: 12px solid var(--primary-color);
    background-color: white;
    position: relative;
    top: -50px;
}

.featured-work .container {
    padding: 8rem 4rem 0;
    transform: skewY(0deg);
}

.featured-work .secondary-heading {
    margin: 0 0 4rem 0;
}

.secondary-heading:after {
    content: '';
    width: 85%;
    height: 35px;
    padding: 0 1rem;
    position: absolute;
    left: -10%;
    top: -20%;
    background-color: rgba(208,168,179,0.2);
    z-index: -1;
}

.featured-work--box {
    position: relative;
    height: auto;
}

.featured-work .featured-work--project {
    padding: 0 0.5rem;
    margin-bottom: 4rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; 
    opacity: 0.4;
    transition: 0.5s ease-in-out; 
    transform: translateY('150px');
}

.featured-work .featured-work--img {
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    border-radius: 5px; 
    flex: 0 0 52%;
    max-width: 52%; 
    width: 100%; 
    height: 400px; 
    filter: grayscale(50%);
    -webkit-filter: grayscale(50%);
}

.featured-work .featured-work--img:before {
    content: ''; 
    position: absolute;
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    background-color: rgba(208,168,179,0.5);
    transition: 0.2s ease-in-out;
}

.featured-work--project .featured-work--info {
    flex: 0 0 40%;
    max-width: 40%;  
    position: relative;
}

.featured-work--project .featured-work--info .featured-work--info-box {
    padding: 3rem 2.8rem;
    background-color: var(--secondary-color-light);
    position: absolute;
    width: 105%;
    top: 35%;
    left: -20%;
}

.featured-work--project .featured-work--info .featured-work--info-box:after {
    content: '';
    width: 100%;
    height: 100%;
    border: 2px solid var(--primary-color-dark);
    position: absolute;
    top: -11%;
    right: -4.5%;
    z-index: -1;
}

.featured-work--project .featured-work--info .featured-work--info-boxalt {
    padding: 3rem 2.8rem;
    background-color: var(--secondary-color-light);
    position: absolute;
    width: 105%;
    top: 35%;
    left: 0;
}

.featured-work--project .featured-work--info .featured-work--info-boxalt:after {
    content: '';
    width: 100%;
    height: 100%;
    border: 2px solid var(--primary-color-dark);
    position: absolute;
    top: -11%;
    right: 3.5%;
    z-index: -1;
}

.featured-work--project .featured-work--info .featured-work--info-box div, 
.featured-work--project .featured-work--info .featured-work--info-boxalt div {
    display: flex;
}

.featured-work--project .featured-work--info .featured-work--tags {
    font-family: CalibreBold;
    color: var(--primary-color-dark);
    padding: 0.5rem 1rem 0.1rem;
    margin-right: 0.6rem;
    border: 2px solid var(--primary-color-dark);
    border-radius: 5px;
}

.featured-work--project .featured-work--title {
    color: var(--primary-color-dark);
    font-size: 1.7rem;
    margin: 0rem;
}

.featured-work--project .featured-work--description {
    margin-top: 0.8rem;
    margin-bottom: 1.3rem;
    font-size: 1.2rem;
}

.featured-work .featured-work--img-one {
    background-image: url(../images/ramanillusions.jpg);
}

.featured-work .featured-work--img-two {
    background-image: url(../images/startup-employee-directory.jpg);
    z-index: -1;
}

.featured-work .featured-work--img-three {
    background-image: url(../images/webondemand.jpg);
}

.featured-work--icons-one {
    margin-left: auto;
 }

.featured-work--icon {
    color: var(--primary-color-dark);
    font-size: 1.5rem;
    margin-left: 0.8rem;
}

/* noteworthy proejcts */ 
.more-projects .container {
    padding: 0 5rem;
    flex-wrap: wrap;
}

.more-projects .column-ten--lg {
    padding-bottom: 3rem;
}

.more-projects .more-projects--contain {
    display: flex; 
    flex-wrap: wrap;
    justify-content: center;
}

.more-projects .more-projects--project {
    flex: 0 0 25%;
    max-width: 25%;
    background-color: var(--secondary-color-light);
    padding: 2.5rem 2rem 2.5rem 2rem;
    transition: 0.4s ease-in-out;
    margin: 0.5rem;
}

.more-projects .more-projects--project:hover {
    transform: scale(0.95);
}

.more-projects .more-projects--icons {
    display: flex; 
    justify-content: flex-end;
    margin-bottom: 1.5rem;
}

.more-projects .more-projects--icons i {
    font-size: 1.2rem;
    color: var(--primary-color-dark);
    margin-left: 0.5rem;
}

.more-projects .more-projects--project h4 {
    color: var(--primary-color-dark);
    font-size: 1.4rem;
    margin: 0;
}

.more-projects .more-projects--project p {
    margin: 0.5rem 0 1.5rem 0;
    font-size: 1.2rem;
}

.more-projects .more-projects--techologies {
    font-size: 1.1rem !important;
    letter-spacing: 0.05rem;
    font-family: CalibreMedium; 
}
/* contact me */ 
.contact-me {
    padding: 6rem 4rem 10rem 4rem;
}

.contact-me .container {
    justify-content: center;
    text-align: center; 
}

.contact-me .secondary-heading {
    font-size: 3rem;
    margin-bottom: 1.3rem; 
}

.contact-me .contact-me--text {
    width: 65%; 
    margin: 0 auto;
    padding-bottom: 3rem;
}

.contact-me i { 
    margin-left: 0.5rem;
    font-size: 1rem;
}

/* copyright */ 
.copyright {
    padding: 1rem 4rem;
    background-color: rgba(208,168,179,0.2);
    border-top: 5px solid var(--primary-color);
}

.copyright .copyright--container {
    display: flex; 
    justify-content: center;
}

.copyright .copyright--container p { 
    font-size: 1.2rem;
    margin: 1rem 0 0.5rem 0;
    font-family: CalibreBold;
}

.copyright i {
    font-size: 1rem;
    margin: 0 0.3rem;
}

/* media queries - large desktop */ 
@media only screen and (min-width: 1200px) {
    .featured-work .featured-work--img {
        height: 355px;
    }
}

@media only screen and (min-width: 1370px) {
    .featured-work .featured-work--img {
        height: 400px;
    }
}

@media only screen and (min-width: 1560px) {
    .main-header .container {
        padding: 12rem 4rem 12rem;
    } 
    .featured-work .featured-work--img {
        height: 450px;
    }
}

@media only screen and (min-width: 1700px) {
    .main-header .container {
        padding: 12rem 4rem 14rem;
    } 
    .featured-work .featured-work--img {
        height: 530px;
    }
}

/* media queries - small laptop */ 
@media only screen and (max-width: 1199px) {
    .featured-work .featured-work--project {
        margin-bottom: 10rem;
    }
    .featured-work .featured-work--img {
        height: 325px;
    }
}

@media only screen and (max-width: 1110px) {
    /* featured projects */
    .featured-work .featured-work--img {
        height: 280px;
    }
    .featured-work--project .featured-work--info .featured-work--info-box div, .featured-work--project .featured-work--info .featured-work--info-boxalt div {
        display: flex;
        flex-wrap: wrap;
    }
    .featured-work--project .featured-work--info .featured-work--tags {
        margin-bottom: 0.8rem;
    }
    .featured-work--project .featured-work--icons-one {
        margin-left: 0;
    }
    .featured-work .featured-work--project-one {
        margin-bottom: 13rem;
    }
    /* noteworthy projects */ 
    .more-projects .container {
        padding: 0 2rem;
    }
    .more-projects .more-projects--project {
        flex: 0 0 40%;
        max-width: 40%;
    } 
}

/* media queries - tablet */ 
@media only screen and (max-width: 991px) {
    .navbar {
        position: fixed;
        width: 100%;
        z-index: 10;
    }
    .navbar .navbar--container {
        padding: 0rem 4rem;
    }
    .navbar .navbar__menu {
        display: none;
    }
    .navbar .navbar__line {
        display: block;
        cursor: pointer;
    }
    /* promo */
    .main-header .container {
        padding: 10rem 4rem 12rem 4rem;
    }
    .main-header .column-seven--lg {
        flex: 0 0 90%;
        max-width: 90%;
    }
    .main-header .main--line {
        left: 4%;
    }
    .main-header .main-heading {
        font-size: 4.5rem;
    }
    .main-header .main-subheading {
        font-size: 1.6rem;
        padding: 0.5rem 0;
        width: 80%;
    }
    .main-header .main--verticle-line, 
    .main-header .main--verticle-line:after {
        height: 80px; 
    }
    /* about me */ 
    .about-me .container {
        flex-wrap: wrap;
    }
    .about-me .column-sixhalf--lg {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .about-me .column-three--lg {
        flex: 0 0 35%;
        max-width: 35%;
        margin-top: 1rem;
        margin-bottom: 5rem;
        margin: auto;
        order: -1;
    }
    /* featured projects */ 
    .featured-work .container {
        padding: 8rem 2rem 0;
    }
    .featured-work .featured-work--project {
        margin-bottom: 10rem;
    }
    .featured-work--project .featured-work--title {
        font-size: 1.4rem;
    }
    .featured-work .featured-work--img {
        height: 250px;
    }
    .featured-work--project .featured-work--info .featured-work--info-box {
        padding: 2.2rem 2rem;
    }
    .featured-work--project .featured-work--info .featured-work--info-box, .featured-work--project .featured-work--info .featured-work--info-boxalt {
        top: 25%;
    }
    .featured-work--project .featured-work--description {
        font-size: 1.1rem;
    }
    .featured-work--project .featured-work--info .featured-work--tags {
        font-size: 0.9rem !important;
    }
}
/* media queries for phones */ 
@media only screen and (max-width: 600px) {
    /* navbar */ 
    .navbar .navbar--container {
        padding: 0 2rem; 
    }
    .navbar .navbar__line {
        padding-right: 0; 
    }
    .navbar .navbar__items--mobile {
        padding-left: 0;
    }
    /* header */ 
    .main-header .container {
        padding: 7rem 2rem 7rem 2rem;
    }
    .main-header .main-heading {
        font-size: 2rem;
        line-height: 0.75;
    }
    .main-header .main-subheading {
        width: 90%;
        font-size: 1.4rem;
    }
    .main-header .main--icon {
        font-size: 1.1rem;
    }
    /* about me */ 
    .about-me .container {
        padding: 8rem 2rem 7rem 2rem;
    }
    .about-me .column-three--lg {
        flex: 0 0 90%;
        max-width: 90%;
        margin-left: 0;
        margin-bottom: 4rem;
    }
    .about-me .about-me--box {
        padding: 0; 
    }
    .secondary-heading, .about-me .about-me--para, .about-me .about-me--button, .contact-me--text, .contact-me--animate {
        opacity: 1 !important;
    }
    /* skills */ 
    .my-skills .my-skills--title {
        padding: 4rem 3rem 0 3rem;
    }
    .my-skills .my-skills--container {
        padding: 0 2rem 10rem 2rem;
    }
    .my-skills .my-skills--row {
        flex-wrap: wrap;
    }
    /* featured work */ 
    .featured-work .featured-work--project {
        opacity: 1;
    }
    .featured-work .featured-work--project-one {
        margin-bottom: 40rem;
    }
    .featured-work .featured-work--project-two {
        margin-bottom: 21rem;
    }
    .featured-work .featured-work--project-three {
        margin-bottom: 25rem;
    }    
    .featured-work .featured-work--img {
        flex: 0 0 100%;
        max-width: 100%;
        height: 180px;
    }
    .featured-work .featured-work--img-two {
        top: -165px;
        left: 25px;
        position: relative;
        z-index: 1;
    }
    .featured-work--project .featured-work--info {
        flex: 0 0 80%;
        max-width: 80%;
    }
    .featured-work--project .featured-work--info .featured-work--info-box {
        width: 95%;
        left: -5%;
    }
    .featured-work--project .featured-work--info .featured-work--info-boxalt {
        width: 95%;
        left: -15%;
    }
    .featured-work--project .featured-work--description {
        margin-bottom: 1.3rem;
    }
    .featured-work--project .featured-work--info .featured-work--tags {
        margin-bottom: 1rem;
    }
    /* noteworthy projects */
    .more-projects {
        padding-top: 5rem;
    }
    .more-projects .more-projects--project, .my-skills .column-two--lg {
        flex: 0 0 90% !important;
        max-width: 90% !important;
        transform: translate(0px, 0px) !important;
        opacity: 1 !important; 
    }
    /* contact me */ 
    .contact-me {
        padding: 4rem 2rem 8rem 2rem;
    }
    .contact-me .secondary-heading {
        font-size: 2.5rem;
    }
    .secondary-heading:after {
        left: 0;
        top: -10%;
    }
    .contact-me .contact-me--text {
        width: 100%;
    }
    /* copyright */ 
    .copyright {
        padding: 1rem 2rem;
        text-align: center;
    }
    .copyright .copyright--container p {
        font-size: 1.1rem;
    }
}